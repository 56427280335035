











































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

type MenuMode = 'TJ' | 'TK' | 'KS' | 'NY'

type Links = {
  lesson: string // 授業を始める
  homework: string // 宿題を始める
  history: string // 学習の振り返り
  goal: string // 目標のチェック
  assistMaterial: string // 補助教材
  mistakes: string // 間違い直し
  timeline: string // タイムライン
}

@Component({
  components: {
    ImageRound,
    LabelBase,
    ColoredBox,
    ButtonBase,
  },
})
export default class StudentSidebar extends Mixins(NowDateTime, ClassModeChangeAble, LoginMethods) {
  // メニューモード
  private menuMode: MenuMode | null = null

  // 都道府県選択状態(入試モードの学習開始URL分けに使用)
  private isSelectedPrefecture = false

  // リンク先オブジェクト
  private links: Links = {
    lesson: '/student/v2/subjects/AI',
    homework: '/student/subjects/SY',
    history: '/student/v2/history/normal/learning-situation',
    goal: '/student/goal-list',
    assistMaterial: '/student/assist-material/operation',
    mistakes: '/student/v2/subjects/MN',
    timeline: '/student/timeline',
  }

  private get cookie() {
    return Vue.prototype.$cookies.get('dataGdls')
  }

  // 生徒情報
  private get student(): { name: string; icon: string; lessonEnable: boolean } {
    return {
      name: this.cookie.userNickname || '',
      icon: this.cookie.userIconUrl || '',
      lessonEnable: this.cookie.isHomeLearningEnabled || false,
    }
  }

  private async mounted(): Promise<void> {
    // ログアウト用のモードを設定
    this.mode = this.MODE.STUDENT
    await this.setSideMenuMode().catch((e) => {
      // cookieが読み込めない場合はログアウト
      this.logout()
    })

    // 入試モードでは生徒の都道府県選択状態取得
    if (this.isEntranceMode) await this.loadStudentPrefecture()

    // 初期値セット
    this.initProperties()
  }

  /**
   * sideMenuModeをCookieにセット
   *
   * classModeの値が基準になるが、
   * 補助教材モード(HJ)の場合sideMenuModeの変更は行わず、
   * 前のclassModeの状態で表示する
   */
  private async setSideMenuMode() {
    const cookie = this.cookie

    if (!cookie.classMode) {
      // classModeがセットされていない場合、ログイン画面からの遷移と判断し、初期値を挿入
      cookie.classMode = this.CLASS_MODE.NORMAL
    } else if (cookie.classMode === this.CLASS_MODE.ASSIST || cookie.classMode === cookie.sideMenuMode) {
      // classModeに'HJ'(補助教材)がセットされている or classModeとsideMenuModeが同じ場合は更新不要
      return
    }

    cookie.sideMenuMode = cookie.classMode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * プロパティの初期化
   *
   * linksをMenuModeに応じて変更
   */
  private initProperties() {
    this.menuMode = this.cookie.sideMenuMode
    switch (this.menuMode) {
      case this.CLASS_MODE.TEST:
        this.links.lesson = '/student/subjects/EN'
        this.links.history = '/student/history/test/step-situation'
        break
      case this.CLASS_MODE.SEMINAR:
        this.links.lesson = '/student/subjects'
        this.links.history = '/student/history/seminar/target-situation'
        break
      case this.CLASS_MODE.ENTRANCE:
        this.links.lesson = this.isSelectedPrefecture ? '/student/subjects' : '/student/prefectures'
        this.links.history = '/student/history/entrance/pretest-situation'
        break
    }
  }

  /**
   * 画面遷移処理
   *
   * 補助教材画面へ遷移する際、classModeに'HJ'をセットする
   * classModeが'HJ'の状態で、その他のメニューを選択した場合、classModeを元の状態に戻す
   * タイムライン画面へ遷移する際、検索条件クッキーを削除する
   */
  private async transition(url: string) {
    const cookie = this.cookie
    if (url === this.links.assistMaterial && cookie.classMode !== this.CLASS_MODE.ASSIST) {
      await this.changeClassMode(this.CLASS_MODE.ASSIST)
    } else if (cookie.classMode === this.CLASS_MODE.ASSIST) {
      await this.changeClassMode(cookie.sideMenuMode)
    }

    if (url == this.links.timeline) {
      cookie['timelineConditions'] = null
      await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    }

    if (this.$route.path != url) this.$router.push(url)
  }

  /**
   * 過去に登録済みの都道府県取得
   */
  private async loadStudentPrefecture() {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/student_prefectures')
    this.isSelectedPrefecture = result.data?.prefectureId ? true : false
  }

  private get isNormalMenu(): boolean {
    return this.menuMode === this.CLASS_MODE.NORMAL
  }

  private get isV2(): boolean {
    return Vue.prototype.$gdlsCookies.isV2()
  }
}
