




import { Component, Vue } from 'vue-property-decorator'
import StudentSidebar from '@/components/organisms/StudentSidebar.vue'
import StudentSidebarV2 from '@/components/organisms/v2/StudentSidebar.vue'
//新しいメニュー バー項目番号 3 を使用
import StudentSidebarV3 from '@/components/organisms/v3/StudentSidebar.vue'

@Component({
  components: {
    StudentSidebar,
    StudentSidebarV2,
    //新しいメニュー バー項目番号 3 を使用
    StudentSidebarV3,
  },
})
export default class SidebarSwitcher extends Vue {
  private get sidebarComponent() {
    //新しいメニュー バー項目番号 3 を使用
    const isV2 = Vue.prototype.$gdlsCookies.isV2OrDemo()
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    return isV3 ? 'StudentSidebarV3' : isV2 ? StudentSidebarV2 : 'StudentSidebar'
  }
}
